.about-us{
  color: #565252;
  .about-img{
    background-image: url("../../assets/child_happy.jpg");
    height: 350px;
  }
  .about-img-2{
    background-image: url("../../assets/mother-educating.jpg");
    height: 310px;
  }
  .about-text{
    h2{
      color: #226D96;
    }
    padding: 40px;
    font-size: 19px;
    font-family: "Poppins Regular";

    h2{
      margin-bottom: 20px;
    }
  }


}

@media (max-width: 1024px){
  .about-us{
    .about-img, .about-img-2{
      height: 230px;
      background-size: cover;
    }
    .about-text{
      font-size: 14px;
      padding: 10px;
    }
  }
}

@media (max-width: 768px) {
  .about-us{
    .about-img, .about-img-2{
      height: 230px;
      background-size: cover;
    }
  }
}
