.footer {
  background-color: #f8f8f8;
  padding: 20px 0;

  p {
    margin-bottom: 0;
    text-align: center;
  }

  .footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: right;

    li {
      display: inline-block;

      a {
        color: #333;
        text-decoration: none;
        margin-left: 10px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
