.homepage.ar {
    direction: rtl; // Set right-to-left direction for Arabic
}

.shop-container {
    padding: 20px;
    background-color: #f9f9f9;

    .shop-title {
        text-align: center;
        font-size: 28px;
        color: #226D96;
        margin-bottom: 30px;
        font-weight: bold;
    }

    .items-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
        justify-items: center;
    }

    .item-card {
        background-color: white;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        text-align: center;

        .item-image {
            width: 100%;
            height: auto;
            border-radius: 8px;
        }

        .item-description {
            margin: 15px 0;
            color: #226D96;
            font-size: 16px;
        }

        .buy-button {
            background-color: #25cadb;
            color: white;
            padding: 10px 20px;
            border-radius: 5px;
            text-decoration: none;
            font-weight: bold;
            transition: background-color 0.3s ease;
            display: inline-block;

            &:hover {
                background-color: #226D96;
            }
        }
    }
}