@font-face {
  font-family: "Libre Franklin";
  src: url("../fonts/LibreFranklin.ttf"), local("Libre Franklin");
}

@font-face {
  font-family: "Libre Franklin Medium";
  src: url("../fonts/LibreFranklin-Medium.ttf"), local("Libre Franklin Medium");
}

@font-face {
  font-family: "Libre Franklin Bold";
  src: url("../fonts/LibreFranklin-Bold.ttf"), local("Libre Franklin Bold");
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans.ttf"), local("Open Sans");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../fonts/Poppins-Bold.ttf"), local("Poppins Bold");
}

@font-face {
  font-family: "Poppins Medium";
  src: url("../fonts/Poppins-Medium.ttf"), local("Poppins Medium");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../fonts/Poppins-Regular.ttf"), local("Poppins Regular");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("../fonts/Poppins-SemiBold.ttf"), local("Poppins SemiBold");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ar {
  text-align: right;
  color: #565252;

  ul {
    direction: rtl; // Right-to-left direction
    text-align: right; // Align text to the right
    list-style-position: inside; // Ensures list markers are inside and to the right
    padding: 0;
    
    // Optional: Customize the list style type for Arabic, e.g., Arabic-Indic numbers
    list-style-type: arabic-indic;
  }

  
}
