.services{
  background-color: rgb(34 109 150 / 5%);
  width: 100%;
  .service{
    text-align: left;
    padding: 30px 0 30px 50px;
    .icon{
      width: 80px;
      height: 80px;
      font-size: 40px;
      border-radius: 50%;
      background-color: #226D96;
      margin-bottom: 10px;
      color: rgb(241 243 245);;
      padding-top: 9px;
      text-align: center;
    }
    .title{
      font-weight: 700;
      font-size: 18px;
    }
    .description{
      font-weight: 400;
    }
  }

  .ar.service {
    flex-direction: row-reverse;
    padding-left: 0;
    text-align: right;
  }
}

@media (max-width: 768px){
  .services{
    margin: 0 !important;
    .service {
      display: flex;
      flex-direction: row;
      padding-left: 25px;

      .message{
        width: 75%;
      }
      .icon {
        margin-right: 15px;
        width: 60px;
        height: 60px;
        font-size: 30px;
      }

      .title {
        font-weight: 700;
        font-size: 16px;
      }

      .description {
        font-weight: 400;
        font-size: 14px;
      }

      &.ar .icon{
        margin-left: 15px;
      }
    }
  }
}