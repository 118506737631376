.header{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  background-image: linear-gradient(to left, #226D96, #25cadb);
  color: #565252;

  .header-content{
    text-align: center;

    .icon{
      font-size: 100px;
    }
    .title{
      font-size: 30px;
      font-weight: 600;
    }
    .slogan{
      font-size: 20px;
    }
  }
}