.appointment-form{
  width: 80%;
  margin: 50px auto;

  h3{
    color: #226D96;
  }

  .form-group{
    label{
      color: #226D96;
    }
    margin: 20px 0;
  }
}

@media (max-width: 768px) {
  .appointment-form{
    .title{
      font-size: 18px;
    }
  }
}