//$primaryColor: #226D96;
//$secondaryColor: #25cadb;
//
//.review-section {
//  padding: 20px;
//  background-color: #f4f4f4;
//
//  h2 {
//    margin-bottom: 10px;
//    color: $primaryColor;
//  }
//
//  .review-container {
//    margin-bottom: 20px;
//    border-radius: 10px;
//    background-color: #fff;
//    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//
//    .review-message {
//      padding: 20px;
//
//      p {
//        margin: 0;
//        color: #333;
//      }
//    }
//  }
//}

//$primaryColor: #226D96;
//$secondaryColor: #25cadb;
//
//.review-section {
//  padding: 20px;
//  background-color: #f4f4f4;
//
//  h2 {
//    margin-bottom: 10px;
//    color: $primaryColor;
//  }
//
//  .review-container {
//    margin-bottom: 20px;
//    border-radius: 10px;
//    background-color: #fff;
//    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//
//    .review-message {
//      padding: 20px;
//      position: relative;
//
//      &::before {
//        content: "";
//        position: absolute;
//        top: 0;
//        left: 0;
//        width: 8px;
//        height: 100%;
//        border-radius: 8px 0 0 8px;
//        background-color: $primaryColor;
//      }
//
//      p {
//        margin: 0;
//        color: #333;
//        font-size: 16px;
//        line-height: 1.5;
//      }
//    }
//  }
//}


$primaryColor: #226D96;
$secondaryColor: #25cadb;

.review-section {
  padding: 20px;
  background-color: #f4f4f4;

  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: $primaryColor;
    text-align: center;
  }

  .review-container {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .review-message {
      p {
        margin: 0;
        color: #333;
        font-size: 18px;
        line-height: 1.5;
      }
    }

    //&::before {
    //  content: "";
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  width: 8px;
    //  height: 100%;
    //  border-radius: 8px 0 0 8px;
    //  background-color: $primaryColor;
    //}
  }
}
