.social-media {
    background-color: #ffffff; // White background for the form
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    .title {
        font-size: 24px;
        color: #226D96; // Main title color
        text-align: center;
        margin-bottom: 20px;
        font-weight: 600;
    }

    .social-media-links {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }

    .shop-link {
        justify-content: center !important;
    }

    .social-link {
        display: flex;
        align-items: center;
        background-color: #25cadb; // Light blue background for social links
        padding: 10px 15px;
        border-radius: 5px;
        text-decoration: none;
        color: white;
        font-size: 16px;
        font-weight: 500;
        transition: background-color 0.3s ease, transform 0.2s ease;
        margin-bottom: 30px;
        justify-content: flex-start;

        img {
            width: 24px;
            height: 24px;
            margin-left: 10px;
        }

        &:hover {
            background-color: #226D96; // Darker blue on hover
            transform: translateY(-3px); // Slight lift on hover
        }
    }
}