.hero {
    display: flex;
    flex-direction: row-reverse;

    .hero-content {
        padding: 50px;

        h1, h2{
            color: #226D96;
        }

        h2 {
            padding-bottom: 20px;
        }

        button {
            padding: 10px 20px;
            font-size: 1.2rem;
            border-radius: 5px;
            background-color: #25cadb;
            color: white;
            border: none;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background-color: #226D96;
            }
        }

    }

    .img-container {
        max-width: 500px;
        padding-top: 50px;
        img {
            width: 100%;
        }
    }

    //responsive reduce padding
    @media (max-width: 768px) {
        .hero-content {
            padding: 15px;
        }
    }
}